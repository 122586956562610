// A good tutorial can be found here: https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html
$borderradius: 3px;
$paddingTopAppContainer: 100px;

$primaryColor:  #CDA73C;
$yellow:        #FFD45C;
$dark-yellow:   #AF8716;
$orange:        #F59300;
$green:         #55B559;
$blue:          #8EBBFF;
$salmon:        #FF6A4C;
$red:           #C50000;
$white:         #FFFFFF;
$lightgray:     #EAEEF2;
$gray:          #A9A9A9;
$gray2:         #707475;
$gray3:         #565656;
$gray4:         #3D3D3D;
$gray5:         #3C3C3C;
$gray6:         #323232;
$gray7:         #333;
$gray8:         #282828;
$black1:        #373536;
$black2:        #232323;
$black3:        #282627;
$black4:        #181718;
$black5:        #1B1B1A;
$black6:        #111111;

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$white-60-opacity: rgba(white, 0.6);
$backgroundColor4Transparent: rgba(35,35,35,0.85);
$transparentColor1: (255,213,106,0.2);
$gray6-60-opacity: #A9A9A999;

$backgroundGoldGradColor: linear-gradient(268.35deg, #AF8716 9.25%, #CDA73C 29.74%, #AF8716 99.7%);
$backgroundTransparentGoldGradColor: radial-gradient(ellipse,rgba(255,217,112,0.4)0%, rgba(255,213,106,0.2) 20%, rgba(255,213,106,0) 70%);
$backgroundGoldGradHomepageHeader: linear-gradient(67.7deg, #B48A2D 36.46%, #D7B442 73.87%, #A77C1B 100%);

$header-height: 70px;

$palette: (
  50: lighten($primaryColor, 25),
  100: lighten($primaryColor, 20),
  200: lighten($primaryColor, 15),
  300: lighten($primaryColor, 10),
  400: lighten($primaryColor,5),
  500: $primaryColor,
  600: darken($primaryColor, 10),
  700: darken($primaryColor, 15),
  800: darken($primaryColor, 20),
  900: darken($primaryColor, 25),
  A100: purple,
  A200: $black3,//used in Fab button background-color
  //#ffab40,
  A400: $green,
  //#ff9100,
  A700: #ff6d00,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: #f9f9f9,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  ),
);
