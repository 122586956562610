$breakpoints: (
  "phone": 599px,
  "tablet": 1280px,
  "desktop": 1281px
) !default;

@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if($breakpoint == 'phone'){
      @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }@else if($breakpoint == 'tablet'){
      @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
    @else{
      @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  }
  @else {
    @warn "Unfortunately, `{$breakpoint}` can not be found" +
    "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

$desktop-app-container-height: calc(100vh - 90px);
$mobile-app-container-height: calc(100vh - 76px);


@mixin tile-hover($scaleFactor) {
  //-webkit-filter: brightness(90%);
  //filter: brightness(0.9);
  transition: 0.1s;

  @include screen("desktop") {
    &:hover {
      transform: scale($scaleFactor);
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .2);
      // -webkit-filter: brightness(110%);
      // filter: brightness(1.1);
    }
  }
}

@mixin image-card-hover($opacityFactor) {

  transition: 0.1s;
  opacity: 0.9;
  filter: grayscale(30%);

  @include screen("desktop") {
    &:hover {
      opacity: $opacityFactor;
      filter: grayscale(10%);
    }
  }
}
